import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { productAction, productConstant } from "../../actions/productList.action";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './myFavStyle.css';
import { toast } from 'react-toastify';

function MyFav() {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const favoritesData = useSelector((state) => state.product.favoritesData);
    const favoritesLoading = useSelector((state) => state.product.favoritesLoading);
    const addFavoritesData = useSelector((state) => state.product.addFavoritesData);
    const allFavorites = useSelector((state) => state.product.allFavorites);
    const favoriteCategories = useSelector((state) => state.product.favoriteCategories);
    const [checked, setChecked] = useState(true);
    const [selectedFilterType, setSelectedFilterType] = useState('');
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [data, setData] = useState(false);
    const [filterStore, setFilterStore] = useState(false);
    const [selectedCateg, setSelectedCateg] = useState({ level: null, categ: null });
    const [checkedValue, setCheckedValue] = useState('')
    const [storeOptions, setStoreOptions] = useState([]);
    const [brandOptions, setBrandOptions] = useState([])

    useEffect(() => {
        setFilterStore(false)
        dispatch({type:productConstant.GET_FAVORITES_REQUEST})
        dispatch(productAction.getFavoritesData({ user_id: auth.userDetails?.id }));
    }, [])

    const handleDeleteFavorite = (pId, e, colorId, index, productDetails) => {
        e.preventDefault();

        if (!auth.auth) {
            toast.error('Login Required!');
            window.location.href = '/login';
            return false;
        }

        // Hide the parent div with the specific ID
        const productSectionId = `productSection${pId}-${index}`;
        const productSectionElement = document.getElementById(productSectionId);

        if (productSectionElement) {
            window.$(`#${productSectionId}`).fadeOut(500);
        }

        // Create a delayed dispatch
        const undoTimeout = setTimeout(() => {
            dispatch(productAction.addToFavorite({ product_id: pId, user_id: auth.userDetails?.id, color_id: colorId, actionType: 'remove', source: 'favorite_page' }, { Token: auth.userDetails?.token }));

            // Reloading page after remove product from the my fav...
            window.location.reload();
        }, 3000);

        // Show undo toast with the option to cancel the timeout
        toast.success(
            <div className=' container row'>
                <div className="col-sm-8 toast-container">
                    <span>Product removed successfully.</span>
                </div>
                <div className="col-sm-4">
                    <button
                        className="btn btn-primary toast-undo-button"
                        onClick={() => handleUndoFavorite(pId, index, undoTimeout)}
                        style={{ fontSize: '14px', height: '30px', paddingRight: '100%', marginTop: '15%' }}
                    >Undo</button>
                </div>
            </div>
            ,
            {
                autoClose: 3000,
            }
        );
    };

    // Handle the undo action
    const handleUndoFavorite = (pId, index, undoTimeout) => {
        clearTimeout(undoTimeout);

        // Show the product section again
        const productSectionId = `productSection${pId}-${index}`;
        const productSectionElement = document.getElementById(productSectionId);

        if (productSectionElement) {
            window.$(`#${productSectionId}`).fadeIn(500);
        }
    };


    const handleShowClick = (e) => {
        const showInformation = document.getElementById('details');
        const details = document.getElementsByClassName(' image-details')
        setChecked(!checked);

        if (showInformation.checked) {
            for (let i = 0; i < details.length; i++) {
                details[i].style.display = 'block';
            }
        } else {
            for (let i = 0; i < details.length; i++) {
                details[i].style.display = 'none';
            }
        }
    };

    const handleTopFavFilter = (e, filtertype) => {
        setFilterStore(true)
        setSelectedStores([])
        setSelectedBrands([])

        const links = document.querySelectorAll(".main li a");
        links.forEach(link => {
            link.style.textDecoration = "none";
        });
        e.target.style.textDecoration = "underline";

        setSelectedFilterType(filtertype);
        dispatch({type:productConstant.GET_ADDTOFAV_FILTTER_REQUEST})
        dispatch(productAction.getAddTOFavouriteFilter({
            filterType: filtertype, user_id: auth.userDetails?.id, store: selectedStores.map(store => store.value),
            brand: selectedBrands.map(brand => brand.value), level: selectedCateg.level, categ: selectedCateg?.categ
        }));
    }

    const handleFavFilter = (selectedStores, selectedBrands) => {
        setData(true)
        const favStoreNames = selectedStores?.map(store => store.value);
        const favBrandNames = selectedBrands?.map(brand => brand.value);

        dispatch(productAction.getAddTOFavouriteFilter({
            user_id: auth.userDetails?.id,
            store: favStoreNames,
            brand: favBrandNames,
            filterType: selectedFilterType,
            level: selectedCateg.level=== null ? '' : selectedCateg.level, categ: selectedCateg?.categ === null ? '' : selectedCateg?.categ
        }));
    };
    // Handling category filter here...
    const handleCategFilter = (e, level, categ) => {
        setCheckedValue(null);
        setFilterStore(true)
        setData(true)
        e.preventDefault();
        setSelectedCateg({ level, categ });

        document.querySelectorAll('#sidebar-category-list a').forEach(link => {
            link.classList.remove('active-category');
        });
        e.target.classList.add('active-category');
        dispatch({type:productConstant.GET_ADDTOFAV_FILTTER_REQUEST})
        dispatch(productAction.getAddTOFavouriteFilter({ level, categ, user_id: auth.userDetails?.id }))

        // Resetting States...
        setSelectedStores([]);
        setSelectedBrands([])
        setSelectedFilterType('')

        const links = document.querySelectorAll(".main li a");
        links.forEach(link => {
            link.style.textDecoration = "none";
        });
    }
    
    // Store options update only when checkedValue !== 'store'
    useEffect(() => {
        if (checkedValue !== 'store') {
            const updatedStoreOptions = filterStore
                ? addFavoritesData?.stores?.map(store => ({
                    value: store.name,
                    label: store.name,
                })) || []
                : favoritesData?.stores?.map(store => ({
                    value: store.name,
                    label: store.name,
                })) || [];
    
            setStoreOptions(updatedStoreOptions);
        }
    }, [filterStore, addFavoritesData, favoritesData, checkedValue]); 
    
    // Brand options update only when checkedValue !== 'brand'
    useEffect(() => {
        if (checkedValue !== 'brand') {
            const updatedBrandOptions = data
                ? addFavoritesData?.brands?.map(brand => ({
                    value: brand.name,
                    label: brand.name,
                })) || []
                : favoritesData?.brands?.map(brand => ({
                    value: brand.name,
                    label: brand.name,
                })) || [];
    
            setBrandOptions(updatedBrandOptions);
        }
    }, [data, addFavoritesData, favoritesData, checkedValue]); // Dependency array includes checkedValue
    
    const handleStoreChange = (selectedOptions) => {
        setCheckedValue('store'); // Set checkedValue to store
        dispatch({ type: 'SET_BRAND_OPTIONS' });
        setData(true);
        setSelectedStores(selectedOptions);
        handleFavFilter(selectedOptions, selectedBrands);
        dispatch({ type: productConstant.GET_ADDTOFAV_FILTTER_REQUEST });
    };
    
    const handleBrandChange = (selectedOptionsBrand) => {
        setCheckedValue('brand'); // Set checkedValue to brand
        setData(true);
        setSelectedBrands(selectedOptionsBrand);
        handleFavFilter(selectedStores, selectedOptionsBrand);
        dispatch({ type: productConstant.GET_ADDTOFAV_FILTTER_REQUEST });
    };
    
    const handleFavClearAll = (e) => {
        setSelectedStores([])
        setSelectedBrands([])
        dispatch({type:productConstant.GET_ADDTOFAV_FILTTER_REQUEST})
        dispatch(productAction.getAddTOFavouriteFilter({ user_id: auth.userDetails?.id, filterType: selectedFilterType }))
    }

    const generateChildCategories = (childCategories) => {
   
        return childCategories?.map((category, index) => (
            <li key={index} data-parent='level3'>
                <a href="javascript:void(0);" onClick={(e) => handleCategFilter(e, 'level3', categoryData(category).id)} id={`${categoryData(category).id}`} style={{ paddingLeft: '40px' }}>{categoryData(category).name}</a>
            </li>
        ));
    };

    const generateChildParentCategories = (childParents) => {
        return childParents?.map((childParent, index) => (
            <li key={index} data-parent='level2'>
                <a href="javascript:void(0);" onClick={(e) => handleCategFilter(e, 'level2', categoryData(childParent.childParent).id)} className="title-sub2" id={`${categoryData(childParent.childParent).id}`} style={{ paddingLeft: '20px' }}>{categoryData(childParent.childParent).name} <em className="mdi mdi-chevron-down"></em></a>

                {childParent.childCategories[0] !== '' && (
                    <ul className='subMenuColor2'>
                        {generateChildCategories(childParent.childCategories)}
                    </ul>
                )}
            </li>
        ));
    }

    const generateParentCategories = (categories) => {
        return categories?.map((category, index) => (
            <li key={index} data-parent='level1'>
                <a href="javascript:void(0);" onClick={(e) => handleCategFilter(e, 'level1', categoryData(category.parent).id)} className={`title `}>{categoryData(category.parent).name} <em className="mdi mdi-chevron-down"></em></a>
                <ul className='subMenuColor1'>
                    {generateChildParentCategories(category.childParents)}
                </ul>
            </li>
        ));
    };

    const categoryData = (response) => {
        const parts = response.split('|');
        const name = parts[0];
        const id = parts[1];

        return { id, name };
    }

    return (
        <section id="body_content myfav-page" className='myfav-page'>
            {auth.auth && favoritesData?.favProducts?.length >= 0 ?
                <div className="container-fluid">
                    <div className="bottom-header">
                        <div className="row">
                            <div className="col-md-2 left-side">
                                <img src="/images/h-menu.png" />
                                {/* <!-- <i className="fa fa-bars menu-icon" aria-hidden="true"></i> --> */}
                                <i className="fa fa-heart heart-icon" aria-hidden="true" ></i>
                            </div>
                            <div className="col-md-7">
                                <ul className="bottom-header-link main" >
                                    <li><a href="javascript:void(0)" id='allItems' onClick={(e) => { handleTopFavFilter(e, 'all_Items') }}>All Items</a></li>
                                    <li><a href="javascript:void(0)">low stock</a></li>
                                    <li><a href="javascript:void(0)">back in stock</a></li>
                                    <li><a href="javascript:void(0)">sold out</a></li>
                                    <li><a href="javascript:void(0)" id='onsale' onClick={(e) => { handleTopFavFilter(e, 'onsale') }}>On sale</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 bottom-header-form-check shows">
                                <label className="box-check" >Show Information
                                    <input type="checkbox" id='details' checked={checked} onClick={(e) => { handleShowClick(e) }} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div id="main-content" className="">
                        <div className="mobile-btn">
                            <button type="button" className="btn filter-btn btn-dark" >Filter</button>
                            <button type="button" className="btn filter-btn btn-dark" data-toggle="modal" data-target="#sortModal">
                                Sort
                            </button>
                        </div>

                        <div className="row">
                            <div className="col-md-2 " id="sidebar">
                                <aside className="left-sidebar sidebar__inner d-lg-block d-none ">
                                    <div className="sidebar-navigation">
                                        <ul className="parent" id='sidebar-category-list'>
                                            <li data-parent='level1'>
                                                <a className='title active-category' href="javascript:void(0);" onClick={(e) => handleCategFilter(e, 'level1', '')}> All <em className="mdi mdi-chevron-down"></em></a>
                                            </li>
                                            {generateParentCategories(favoriteCategories)}
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-md-10 content" id="content">
                                {/* <div className="row mb-2">
                                    <div className="col-md-10"></div>
                                    <div className="col-md-2 create-look"><a href="#">Create a look</a></div>
                                </div> */}
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="dropdown-area">
                                                <div className='store gap'>
                                                    <ReactMultiSelectCheckboxes
                                                        className='gap-1'
                                                        id='storess'
                                                        options={storeOptions}
                                                        onChange={handleStoreChange}
                                                        value={selectedStores}
                                                        placeholderButtonLabel=" Select Store "
                                                        placeholder=' select store '
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            width: '190px',
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: 'hotpink',
                                                                primary: 'black',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                                <div className='brand gap' >
                                                    <ReactMultiSelectCheckboxes
                                                        options={brandOptions}
                                                        onChange={handleBrandChange}
                                                        value={selectedBrands}
                                                        placeholderButtonLabel=" Select Brand "
                                                        placeholder=' Select Brand '
                                                    />
                                                </div>

                                                <div className='condition gap'>
                                                    <select id="gender2" className="form-control ">
                                                        <option selected>Condition</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                </div>

                                                <div className='Deals gap'>
                                                    <select className="form-control dp-margin-alternative" id="gender2">
                                                        <option selected>Deals</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                </div>

                                                <div className='myList gap'>
                                                    <select id="gender2" className="form-control ">
                                                        <option selected>My list</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                </div>
                                                <p className="clear-filter"><a href="javascript:void(0)" onClick={(e) => { handleFavClearAll(e) }}>Clear all</a></p>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-2">
                                            <div className="action-icon">
                                                <a href="#"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                                                <a href="#"><i className="fa fa-share" aria-hidden="true"></i></a>
                                            </div>
                                        </div> */
                                        }
                                    </div>
                                </div>

                                <div className="row">
                                {favoritesLoading ?
                                    <div>
                                        <div className="loader spinner-border m-5 d-table m-auto" role="status">
                                            <span className="visually-hidden"></span>
                                        </div>
                                        <span className=' m-5 d-table m-auto'>Loading...</span>
                                    </div>

                                    :
                                
                                    auth.auth && data ? (
                                        addFavoritesData?.favProducts?.length > 0 ? (
                                        addFavoritesData.favProducts.map((product, index) => {
                                            if (product?.productDetail) {
                                            return (
                                                <div
                                                className="text-center col-md-1-5 col-lg-2-5"
                                                id={`productSection${product.product_id}-${index}`}
                                                >
                                                <div className="product-cnt">
                                                    <div className="post-image my-fav">
                                                    <div className="hover-icon-product-image post-heading">
                                                      
                                                        <a
                                                        href="javascript:void(0);"
                                                        onClick={(e) => handleDeleteFavorite(product?.product_id, e, product?.color_id, index, product)}
                                                        >
                                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                    <span className="mb">
                                                        <a target="_blank" className="ml" href={product?.productDetail?.product_url}>
                                                        <img
                                                            src={
                                                            product?.productDetail?.vendor_id === 1
                                                                ? product?.productDetail?.color_primary_image
                                                                : product?.productDetail?.image_path
                                                            }
                                                            className="img-fluid"
                                                        />
                                                        </a>
                                                    </span>
                                                    <div className="container-desp image-details">
                                                        <div className="text-center">{product?.productDetail?.brand_name}</div>
                                                        <div className="text-center">
                                                        <a target="_blank" className="ml" href={product?.productDetail?.product_url}>
                                                            {product?.productDetail?.product_name}-{product?.productDetail?.product_id}
                                                        </a>
                                                        </div>
                                                        <div className="text-center">
                                                        {product?.productDetail?.price === product?.productDetail?.retail_price ? (
                                                            <span className="">
                                                            <small>${product?.productDetail?.price}</small>
                                                            </span>
                                                        ) : (
                                                            <span className="">
                                                            <strike>${product?.productDetail?.retail_price}</strike>
                                                            <small>${product?.productDetail?.price}</small>
                                                            </span>
                                                        )}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            );
                                            }
                                        })
                                        ) : (
                                        <p style={{ textAlign: 'center' }}>No Record Found</p>
                                        )
                                    ) : (
                                        auth.auth && favoritesData?.favProducts?.length > 0 ? (
                                        favoritesData.favProducts.map((product, index) => {
                                            if (product?.productDetail) {
                                            return (
                                                <div
                                                className="text-center col-md-1-5 col-lg-2-5"
                                                id={`productSection${product.product_id}-${index}`}
                                                >
                                                <div className="product-cnt">
                                                    <div className="post-image my-fav">
                                                    <div className="hover-icon-product-image post-heading">
                                                        {/* <a href="#"><i className="fa fa-share" aria-hidden="true"></i></a>
                                                        <a href="#"><i className="fa fa-files-o" aria-hidden="true"></i></a> */}
                                                        <a
                                                        href="javascript:void(0);"
                                                        onClick={(e) => handleDeleteFavorite(product?.product_id, e, product?.color_id, index, product)}
                                                        >
                                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                    <span className="mb">
                                                        <a target="_blank" className="ml" href={product?.productDetail?.product_url}>
                                                        <img
                                                            src={
                                                            product?.productDetail?.vendor_id === 1
                                                                ? product?.productDetail?.color_primary_image
                                                                : product?.productDetail?.image_path
                                                            }
                                                            className="img-fluid"
                                                        />
                                                        </a>
                                                    </span>
                                                    <div className="container-desp image-details">
                                                        <div className="text-center">{product?.productDetail?.brand_name}</div>
                                                        <div className="text-center">
                                                        <a target="_blank" className="ml" href={product?.productDetail?.product_url}>
                                                            {product?.productDetail?.product_name}-{product?.productDetail?.product_id}
                                                        </a>
                                                        </div>
                                                        <div className="text-center">
                                                        {product?.productDetail?.price === product?.productDetail?.retail_price ? (
                                                            <span className="">
                                                            <small>${product?.productDetail?.price}</small>
                                                            </span>
                                                        ) : (
                                                            <span className="">
                                                            <strike>${product?.productDetail?.retail_price}</strike>
                                                            <small>${product?.productDetail?.price}</small>
                                                            </span>
                                                        )}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            );
                                            }
                                        })
                                        ) : (
                                        <p style={{ textAlign: 'center' }}>No Record Found</p>
                                        )
                                    )
                                }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : (
                    <tr style={{ paddingTop: '10%', height: '20rem' }} className="text-center no-record-found d-table m-auto"><td>You haven't saved any items yet. Save your favorite products.</td></tr>)
            }


            {/* <!-- filter menu  --> */}
            {/* <div id="filterMboile" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onClick="closeNav()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18px"
                    height="18px" focusable="false">
                    <path class="cross-svg-icon" d="M2 2l8 8m0-8l-8 8"></path>
                    </svg>
                </a>
                <button class="btn btn-theme" onClick="closeNav()" style="width: 96%;">Done</button>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    Size
                                </a>
                            </h6>

                        </div>
                        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                            <div class="panel-body">
                                <label class="box-check">small
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Medium
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Large
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    Gender
                                </a>
                            </h6>

                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                            <div class="panel-body">
                                <label class="box-check">Men
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Women
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Unisex
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingThree">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"
                                    aria-expanded="false" aria-controls="collapseThree">
                                    Brand
                                </a>
                            </h6>

                        </div>
                        <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                            <div class="panel-body">
                                <label class="box-check">Reebok
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Skechers
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Puma
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFour">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour"
                                    aria-expanded="false" aria-controls="collapseFour">
                                    Material
                                </a>
                            </h6>

                        </div>
                        <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                            <div class="panel-body">
                                <label class="box-check">Denim
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Cotton
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Linen
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">100% Cotton
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">100% Linen
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingFive">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive"
                                    aria-expanded="false" aria-controls="collapseFive">
                                    Sale
                                </a>
                            </h6>

                        </div>
                        <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                            <div class="panel-body">
                                <label class="box-check">Regular
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Sale
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSix">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix"
                                    aria-expanded="false" aria-controls="collapseSix">
                                    Price
                                </a>
                            </h6>

                        </div>
                        <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                            <div class="panel-body">
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">0 - 25
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingSeven">
                            <h6 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven"
                                    aria-expanded="false" aria-controls="collapseSeven">
                                    Color
                                </a>
                            </h6>

                        </div>
                        <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
                            <div class="panel-body">
                                <label class="box-check">Black
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Red
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Blue
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Black
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Red
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Blue
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Black
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Red
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                                <label class="box-check">Blue
                                    <input type="checkbox" checked="checked" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
            {/* <!-- end filter menu  --> */}

            {/* <!-- modal sort  --> */}
            {/* <div class="modal fade" id="sortModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">

                <div class="modal-body">
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" data-dismiss="modal" />
                        <label class="custom-control-label" for="customRadio1">Newest</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" data-dismiss="modal" />
                        <label class="custom-control-label" for="customRadio2">Trending</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" data-dismiss="modal" />
                        <label class="custom-control-label" for="customRadio3">Price High to Low</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio4" name="customRadio" class="custom-control-input" data-dismiss="modal" />
                        <label class="custom-control-label" for="customRadio4">Price Low to High</label>
                      </div>
                </div>

              </div>
            </div>
        </div> */}

        </section>
    )
}

export default MyFav;